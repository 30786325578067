import { twMerge } from 'tailwind-merge'

export const endpoints = {
  VITE_ASSETS_API: import.meta.env.VITE_ASSETS_API,
  VITE_ANNOTATOR_API: import.meta.env.VITE_ANNOTATOR_API,
  VITE_PROJECTS_API: import.meta.env.VITE_PROJECTS_API,
  VITE_BOXIFY_API: import.meta.env.VITE_BOXIFY_API,
  VITE_FEATURE_FLAGS_API: import.meta.env.VITE_FEATURE_FLAGS_API,
  VITE_WAVESYNC_API: import.meta.env.VITE_WAVESYNC_API,
  VITE_AUTH_API: import.meta.env.VITE_AUTH_API,
  VITE_LICENSES_API: import.meta.env.VITE_LICENSES_API,
  VITE_DEVICES_API: import.meta.env.VITE_DEVICES_API,
  VITE_ONCLOUD_API: import.meta.env.VITE_ONCLOUD_API,
  GRAPHQLURL:
    import.meta.env.VITE_DEV_GRAPHQLURL || `https://${window.location.hostname}/hasura/v1/graphql`,
  VITE_DATADOG_CLIENT_TOKEN: 'REPLACE_VITE_DATADOG_CLIENT_TOKEN',
  VITE_DATADOG_URL: import.meta.env.VITE_DATADOG_URL,
}

export const userKeepAliveVars = {
  // NOTE: We now use 'sessionTimeout' which we read from the miniMe object to determine the
  // maximum inactivity time in minutes. If this is -1 then there is no timeout on Duclo backend.

  // The default timeBetweenPingMs is 5 minutes.
  timeBetweenPingMs: parseInt(import.meta.env.VITE_TIME_BETWEEN_PING_IN_MINUTES) * 60 * 1000,
  // How often we check for user activity. The default is 60000 (one minute), but for
  // testing set it to every second.
  userActivityIntervalMs: parseInt(import.meta.env.VITE_USER_ACTIVITY_INTERVAL_MS),
  // We will check if we need to send a ping every 5 minutes (1/2 minute for testing)
  checkPingIntervalMs: parseFloat(import.meta.env.VITE_CHECK_PING_INTERVAL_IN_MINUTES) * 60 * 1000,
} as const

export const storageKeys = {
  ORG_ID: 'org_id',
  USER_ID: 'user_id',
  SESSION_TIMEOUT_MINUTES: 'session_timeout',
  LAST_ACTIVE_TIME: 'last_active_time',
  LAST_PING_TIME: 'last_ping_time',
  WAVESYNC_USERNAME: 'wavesync_username',
  GRAPHQL_TRAINING_INITIALIZED: 'graphql_training_initialized',
} as const

export const annotationStateColors = {
  USER_FOUND: '#608CFF',
  AI_FOUND: '#6FC02E',
  INCORRECT: '#FF0000',
} as const

export const verificationLabelStatus = {
  INIT: 'init',
  ACTIVE: 'active',
  ERROR: 'error',
} as const

export const PROJECT_STATUS = {
  init: 'Untrained',
  annotated: 'With annotations',
  preparing_assets: 'Preparing assets',
  in_training: 'Training in Progress',
  compiling: 'Compiling',
  compiling_error: 'Compiling error',
  trained: 'Trained',
  validated: 'Model Validated',
  error: 'Processing error',
} as const

export const PROJECT_COMPILED_STATUS = {
  starting: 'starting',
  started: 'started',
  compiled: 'compiled',
  error: 'error',
} as const

export const VIDEO_STATUS = {
  active: 'active',
  pending: 'pending',
  deleted: 'deleted',
} as const

export const annotationType = {
  INFERENCE: 'inference',
  ANNOTATION: 'annotation',
  INCORRECT: 'incorrect',
} as const

export const cloudPlatformLoginUrls = [
  {
    name: 'dev',
    flexaiUrl: 'dev.flexai.hanwhavision.cloud',
    cloudUrl: 'https://dev.platform.hanwhavision.cloud',
  },
  {
    name: 'pvtdev',
    flexaiUrl: 'hv-dev-internal.flexai.hanwhavision.cloud',
    cloudUrl: 'https://dev.platform.hanwhavision.cloud',
  },
  {
    name: 'ci',
    flexaiUrl: 'ci.flexai.hanwhavision.cloud',
    cloudUrl: 'https://dev.platform.hanwhavision.cloud',
  },
  {
    name: 'qa',
    flexaiUrl: 'qa.flexai.hanwhavision.cloud',
    cloudUrl: 'https://qa.platform.hanwhavision.cloud',
  },
  {
    name: 'relqa',
    flexaiUrl: 'hv-relqa-internal.flexai.hanwhavision.cloud',
    cloudUrl: 'https://qa.platform.hanwhavision.cloud',
  },
  {
    name: 'prod',
    flexaiUrl: 'flexai.hanwhavision.cloud',
    cloudUrl: 'https://platform.hanwhavision.cloud',
  },
] as const

export const cloudPlatformAccountSettingsUrls = [
  {
    name: ['prod'],
    url: 'https://platform.hanwhavision.cloud/accountsettings.html',
  },
  {
    name: ['dev', 'ci', 'pvtdev'],
    url: 'https://dev.platform.hanwhavision.cloud/accountsettings.html',
  },
  {
    name: ['qa', 'relqa'],
    url: 'https://qa.platform.hanwhavision.cloud/accountsettings.html',
  },
] as const

export const flexAiSupportUrl =
  'https://support.hanwhavisionamerica.com/hc/en-us/sections/25223055328283-FLEX-AI'

export const deviceHelpArticle =
  'https://support.hanwhavisionamerica.com/hc/en-us/articles/25726838758043-Licensing-Hanwha-Vision-Cloud-Products'

export const projectsSortOptions = [
  {
    id: '1',
    value: 'Alphabetical (A-Z)',
    label: 'Alphabetical (A-Z)',
    sortProperty: 'name',
    sortDirection: 'asc',
  },
  {
    id: '2',
    value: 'Alphabetical (Z-A)',
    label: 'Alphabetical (Z-A)',
    sortProperty: 'name',
    sortDirection: 'desc',
  },
  {
    id: '3',
    value: 'Updated (most recent)',
    label: 'Updated (most recent)',
    sortProperty: 'updatedAt',
    sortDirection: 'desc',
  },
  {
    id: '4',
    value: 'Updated (least recent)',
    label: 'Updated (least recent)',
    sortProperty: 'updatedAt',
    sortDirection: 'asc',
  },
] as const

export const errorMessages = {
  WAVESYNC_USER_ID_IS_REQUIRED: 'User ID is required',
  WAVESYNC_PASSWORD_IS_REQUIRED: 'Password is required',
  WAVESYNC_ORG_ID_USER_ID_NOT_FOUND: 'OrgId or UserId is not defined',
  WAVESYNC_LOGIN_FAILED: 'The email or password you entered is incorrect. Please try again.',
  WAVESYNC_NO_VIDEOS:
    'This system does not have any videos that FLEX AI can access. Please review on WaveSync and try again.',
  WAVESYNC_NO_SYSTEMS:
    'FLEX AI is unable to access any system in this account. Please review on WaveSync and try again.',
}

export const DEFAULT_CONFIDENCE_LEVEL = 20

export const projectStatusStyles = {
  trained: 'border-status-success text-status-success bg-status-success-light dark:bg-[#132404]',
  init: 'border-status-info text-status-info bg-status-info-light dark:bg-[#000B29]',
  preparing_assets: 'border-semantic-secondary text-semantic-secondary bg-transparent',
  in_training: 'border-semantic-secondary text-semantic-secondary bg-transparent',
  compiling: 'border-semantic-secondary text-semantic-secondary bg-transparent',
  annotated: 'border-status-info text-status-info bg-status-info-light dark:bg-[#000B29]',
  error: 'border-status-error text-status-error bg-status-error-light dark:bg-[#2B0000]',
  compiling_error: twMerge('border-hv-status-error *:text-hv-status-error'),
} as const

export const tagClasses = {
  init: 'border-status-info bg-status-info-light text-status-info dark:bg-[#000B29]',
  active: 'border-status-info bg-status-info-light text-status-info dark:bg-[#000B29]',
  partial: 'border-status-warning bg-status-warning-light text-status-warning dark:bg-[#3A1903]',
  done: 'border-status-success bg-status-success-light text-status-success dark:bg-[#132404]',
  error: 'border-status-warning bg-status-warning-light text-status-warning dark:bg-[#3A1903]',
  processing: 'border-neutral-grey-7 text-neutral-grey-7 min-w-[100px] justify-start',
} as const

// View modes for project page
export const projectViewModes = {
  INITIAL: 'INITIAL',
  REVIEW: 'REVIEW',
  IMPROVE: 'IMPROVE',
  ADD_MORE: 'ADD_MORE',
} as const

export const projectImproveViewModes = {
  IMPROVE: projectViewModes.IMPROVE,
  ADD_MORE: projectViewModes.ADD_MORE,
} as const

export type ProjectViewMode = keyof typeof projectViewModes
type ProjectImproveViewMode = keyof typeof projectImproveViewModes

export const isImproveViewMode = (
  viewMode: ProjectViewMode
): viewMode is ProjectImproveViewMode => {
  return viewMode in projectImproveViewModes
}

export const projectFormCharLimits = {
  nameLimit: 60,
  notesLimit: 500,
  objectNameLimit: 40,
} as const

export const clipStatusLabel = {
  init: 'processing',
  active: 'ready to view',
  error: 'applying model failed',
} as const

export const envVars = {
  ENABLE_PROXY: import.meta.env.VITE_ENABLE_PROXY === 'true',
  DEV_ORGID: import.meta.env.VITE_DEV_ORGID,
  DEV_USERID: import.meta.env.VITE_DEV_USERID,
  DEV_DISABLE_REDIRECTS: import.meta.env.VITE_DEV_DISABLE_REDIRECTS === 'true',
  IS_DEVELOPMENT: import.meta.env.DEV,
} as const

export const annotationStatusColor = {
  inference: annotationStateColors.AI_FOUND,
  incorrect: annotationStateColors.INCORRECT,
  annotation: annotationStateColors.USER_FOUND,
} as const

export const hanwhaAppsUrls = [
  {
    name: 'CloudPortal',
    urls: {
      dev: 'https://dev.platform.hanwhavision.cloud/',
      qa: 'https://qa.platform.hanwhavision.cloud/',
      prod: 'https://platform.hanwhavision.cloud/',
    },
  },
  {
    name: 'DmPro',
    urls: {
      dev: 'https://dev.dmpro.hanwhavision.cloud/',
      qa: 'https://qa.dmpro.hanwhavision.cloud/',
      prod: 'https://dmpro.hanwhavision.cloud/',
    },
  },
  {
    name: 'SightMind',
    urls: {
      dev: 'https://dev.sightmind.hanwhavision.cloud/',
      qa: 'https://qa.sightmind.hanwhavision.cloud/',
      prod: 'https://sightmind.hanwhavision.cloud/',
    },
  },
  {
    name: 'OnCloud',
    urls: {
      dev: 'https://dev.oncloud.hanwhavision.cloud/',
      qa: 'https://qa.oncloud.hanwhavision.cloud/',
      prod: 'https://oncloud.hanwhavision.cloud/',
    },
  },
] as const

export const MAX_REFERENCE_POINTS = 10
export const VMS_ITEMS_PER_PAGE = 6

export const responseStatus = {
  CREATED: 201,
  OK: 200,
}

export const ONCLOUD_FLEXAI_TAG = 'flex_ai'

export const urlParams = {
  ORG_ID: 'orgId',
} as const

export const headerBannerTypes = {
  info: 'bg-hv-status-info',
  success: 'bg-hv-status-success',
  warning: 'bg-hv-status-warning',
  error: 'bg-hv-status-error',
} as const

export const licenseStatusLabels = {
  ACTIVE: 'Active',
  ASSIGNED_ACTIVATION_PENDING: 'Stacking',
  STACKED1: 'Stacking 1',
  STACKED2: 'Stacking 2',
  UNASSIGNED: 'Available',
  EXPIRED: 'Expired',
} as const

export const MAX_FILE_NAME_LENGTH = 50
export const FALLBACK_FRAMERATE = 20
